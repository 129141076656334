import React from "react"
import { animated, useSpring, config } from "react-spring"
import { makeStyles } from "@material-ui/core"

type Props = {
  codeFirst: { html: string }
  codeSecond: { html: string }
  langCodeFirst: string
  langCodeSecond: string
  isTranslated: boolean
  className?: string
}

const useStyles = makeStyles(theme => ({
  container: {
    // display: 'flex',
    // justifyContent: "center"
  },
}))

export default function (props: Props) {
  const classes = useStyles()
  const {
    className,
    codeFirst,
    codeSecond,
    isTranslated,
    langCodeFirst,
    langCodeSecond,
  } = props

  const myConfig = config.gentle

  // const [isTranslate, setTranslate] = useState(false)
  const animationFirst = useSpring({
    to: {
      display: isTranslated ? "none" : "block",

      opacity: isTranslated ? 0 : 1,
    },
    config: myConfig,
  })
  const animationSecond = useSpring({
    to: {
      display: isTranslated ? "block" : "none",

      opacity: isTranslated ? 1 : 0,
    },
    config: myConfig,
  })

  return (
    <>
      <animated.div
        key={1}
        lang={langCodeFirst}
        style={animationFirst}
        className={className}
        dangerouslySetInnerHTML={{ __html: codeFirst.html }}
      />
      <animated.div
        key={2}
        lang={langCodeSecond}
        style={animationSecond}
        className={className}
        dangerouslySetInnerHTML={{ __html: codeSecond.html }}
      />
    </>
  )
}
