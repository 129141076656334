import React, { FunctionComponent } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Typography, Tooltip, Divider } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  container: {
    // backgroundColor: "#0f4c81", // rgba(0, 0, 0, 0.87)
    marginBottom: "30px",
    // backgroundColor: "#ccc",
    // height: "150px",
  },
  gridContainer: {
    paddingTop: "30px",
    paddingBottom: "30px",
  },
  libraryLink: {
    cursor: "pointer",
  },
  featureImage: {
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      height: "80px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "50px",
    },
  },
  title: {
    color: "#0f4c81",
    textAlign: "center",
    width: "100%",
    // paddingTop: "0.5rem",
    paddingBottom: "28px",
    fontWeight: 600,
    fontSize: "1.75rem",
  },
}))

type Props = {
  currentPage:
    | "i18nAngular"
    | "i18nReact"
    | "i18nlibraries"
    | "i18nRubyRails"
    | "markdown"
    | "vue"
    | "homepage"
  title?: string
}

export const CompatibleLibraries: FunctionComponent<Props> = ({
  currentPage,
  title,
}) => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query compatibleLibrariesQuery {
      i18nAngular: file(relativePath: { eq: "logos/i18nAngular.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 100
            quality: 95
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
      i18nReact: file(relativePath: { eq: "logos/i18nReactLP.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 100
            quality: 95
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
      i18nlibraries: file(relativePath: { eq: "libraries/i18nReact.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 100
            quality: 95
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
      i18nRubyRails: file(relativePath: { eq: "libraries/i18nRails.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 100
            quality: 95
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
      markdown: file(relativePath: { eq: "libraries/i18nMarkdown.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 100
            quality: 95
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
      vue: file(relativePath: { eq: "libraries/i18nVue.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 100
            quality: 95
            layout: FIXED
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  return (
    <div className={classes.container}>
      <Container>
        <Divider />
        <Grid
          container
          alignItems="center"
          justify="space-around"
          className={classes.gridContainer}
        >
          <Typography variant="h3" className={classes.title}>
            {title ? title : "More Technologies & i18n Libraries"}
          </Typography>
          <Tooltip title="More about Angular i18n translations">
            <Link
              to="/translate-angular-i18n"
              hidden={currentPage === "i18nAngular"}
              className={classes.libraryLink}
            >
              <GatsbyImage
                image={data.i18nAngular.childImageSharp.gatsbyImageData}
                alt="Logo Angular ngx-translate for machine translation"
                className={classes.featureImage}
              />
            </Link>
          </Tooltip>

          {/*
      // General i18n libraries now supported by vue, react and angular 
          <Tooltip title="More about currently supported i18n libraries">
            <Link
              to="/translate-i18n-locale"
              hidden={currentPage === "i18nlibraries"}
              className={classes.libraryLink}
            >
              <GatsbyImage
                fixed={data.i18nlibraries.childImageSharp.fixed}
                alt="General translation of i18n libraries for machine translation"
                className={classes.featureImage}
              />
            </Link>
          </Tooltip>
      */}
          <Tooltip title="More about Rails i18n translations">
            <Link
              to="/translate-rails-i18n"
              hidden={currentPage === "i18nRubyRails"}
              className={classes.libraryLink}
            >
              <GatsbyImage
                image={data.i18nRubyRails.childImageSharp.gatsbyImageData}
                alt="Translation of i18n Ruby on Rails locales"
                className={classes.featureImage}
              />
            </Link>
          </Tooltip>

          <Tooltip title="More about React i18n translations">
            <Link
              to="/translate-react-i18n"
              hidden={currentPage === "i18nReact"}
              className={classes.libraryLink}
            >
              <GatsbyImage
                image={data.i18nReact.childImageSharp.gatsbyImageData}
                alt="Logo React compatible libraries for machine translation"
                className={classes.featureImage}
              />
            </Link>
          </Tooltip>

          <Tooltip title="More about Markdown translation">
            <Link
              to="/translate-markdown"
              hidden={currentPage === "markdown"}
              className={classes.libraryLink}
            >
              <GatsbyImage
                image={data.markdown.childImageSharp.gatsbyImageData}
                alt="Translation of Markdown files"
                className={classes.featureImage}
              />
            </Link>
          </Tooltip>

          <Tooltip title="More about Vue i18n translation">
            <Link
              to="/translate-vue-i18n"
              hidden={currentPage === "vue"}
              className={classes.libraryLink}
            >
              <GatsbyImage
                image={data.vue.childImageSharp.gatsbyImageData}
                alt="Translation of Vue i18n locales"
                className={classes.featureImage}
              />
            </Link>
          </Tooltip>
        </Grid>
        <Divider />
      </Container>
    </div>
  )
}
